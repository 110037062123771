.profile-component {
  width: 100%;
  margin: auto;
}

.profile-component .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border: 1px lightgray solid;
}

.edit-button-container {
  position: absolute;
  top: 0%;
  right: 0%;
  z-index: 1;
}

.edit-btn {
  border-radius: 0 !important;
}

.profile-component .top .left {
  text-align: center;
  width: 50%;
}
.profile-component .top .left .sponsorId {
  margin: 6px 0px;
  font-size: 1em;
  font-family: Arial, Helvetica, sans-serif;
}
.profile-component .top .left .username {
  margin: 6px 0px;
  font-size: 25px;
  font-family: "Charm", cursive;
  letter-spacing: 3px;
}

.profile-component .top .left .img-container {
  width: 200px;
  height: 200px;
  margin: auto;
  cursor: pointer;
}

.profile-component .top .left .img-container img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.profile-component .top .left .left-side-button-container {
  padding: 10px 0px;
}

.profile-component .top .left .left-side-button-container .request-epin {
  margin-left: 10px;
}

.profile-component .top .right {
  width: 50%;
  padding: 10px;
}

.profile-component .top .right .general-info {
  position: relative;
  height: 100%;
}

.profile-component .top .right .general-info {
  position: relative;
  height: 100%;
}
.profile-component .top .right .general-info > .FirstNdLastNameWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.2em;
}
.profile-component .top .right .general-info > .FirstNdLastNameWrapper > .details-wrapper {
  width: calc(40% - 0.1em);
}
.profile-component .top .right .general-info .details-wrapper {
  width: 80%;
}

.profile-component .top .right .general-info .details-wrapper .heading {
  color: rgb(70, 70, 70);
  font-family: "Lato", sans-serif;
  font-weight: bold;
}

.profile-component .top .right .general-info .details-wrapper .result {
  border: 1px lightgray solid;
  padding: 10px;
  margin: 5px 0px 20px 0px;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .profile-component .top {
    flex-direction: column;
  }
  .profile-component .top .right .general-info > .FirstNdLastNameWrapper > .details-wrapper {
    width: calc(50% - 0.1em);
  }
  .profile-component .top .left {
    width: 100%;
    margin: 40px 0px 15px 0px;
  }

  .profile-component .top .left .img-container {
    width: 150px;
    height: 150px;
  }

  .profile-component .top .right {
    width: 100%;
  }

  .profile-component .top .right .general-info .details-wrapper {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
}
