.table {
  background-color: #ffffff;
  border: 1px #ffffff solid;
  width: 49%;
  border-radius: 5px;
}

.table .table-heading {
  margin: 10px 20px;
}

.more-icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.board_link {
  font-weight: 100;
  font-size: 13px;
  color: #7b1fa2;
}

.inactive-transaction {
  background-color: #f7f7f7;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .table {
    width: 100%;
    margin: 10px 0px;
  }
}
