::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  cursor: pointer;
}

::-webkit-scrollbar-track {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb {
  background: #9c27b0;
  cursor: pointer;
}

.input-form_flex {
  display: flex;
}

input,
select,
textarea {
  border: 1px rgb(75, 75, 75) solid;
  border-radius: 5px;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  outline: none;
  padding: 0px 15px;
  height: 40px;
  width: 100%;
  margin: 7px 0px 0px 0px;
}

textarea {
  height: 200px;
  padding-top: 20px;
}

.page {
  display: flex;
  background-color: #fafafa;
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 40%;
  margin: auto;
}

.login-page .form-part {
  border: 1px #ebebeb solid;
  border-radius: 5px;
}

.sidebar {
  width: 200px;
}

.right-container {
  width: 85%;
}

.rest .card-container {
  width: 95%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
}

.rest .graph-container {
  width: 95%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.rest .graph-container .chart {
  width: 49%;
}

.rest .table-container,
.rest .data-table-container,
.rest .profile-container,
.rest .invoice-container,
.rest .epinrequestcontainer,
.rest .cateDetailsContainer,
.rest .category_giftCard_container,
.rest .gcDetailsContainer {
  width: 95%;
  margin: 20px auto;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.rest .epinrequestcontainer {
  flex-direction: column;
}

.rest .epinrequestcontainer form {
  width: 100%;
}

.payment-methods-container {
  width: 80%;
  margin: auto;
}

.nf-container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nf-container .nf_image_container {
  height: 70%;
  width: 50%;
  /* border: 1px red solid; */
}

.nf-container .nf_image_container img {
  height: 100%;
  width: 100%;
}

.epin-container {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}
.epin-container .epin-item {
  border: 1px #9c27b0 solid;
  margin-right: 10px;
  padding: 10px;
  cursor: pointer;
}

.epin-container .epin-item .epin-id {
  font-weight: 500;
  margin-bottom: 5px;
}

.epin-container .epin-item .epin-bal {
  color: #9c27b0;
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .right-container {
    width: 100%;
  }

  .payment-methods-container {
    width: 100%;
  }

  .nf-container .nf_image_container {
    height: 50%;
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .rest .card-container {
    flex-wrap: wrap;
  }

  .rest .graph-container {
    flex-direction: column;
  }

  .rest .graph-container .chart {
    width: 100%;
  }

  .rest .table-container,
  .rest .data-table-container {
    flex-direction: column;
  }

  .nf-container .nf_image_container {
    height: 40%;
    width: 100%;
  }
}
