.kyc-request-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 20px auto;
  padding: 20px 10px;
  background-color: #ffffff;
}

.kyc-details_left {
  width: 50%;
}

.kyc_img_container {
  width: 50%;
  text-align: center;
  margin: 30px auto;
}

.kyc_img_container h4 {
  font-weight: 700;
}

.kyc_img_container img {
  width: 100%;
  margin: 10px 0px;
  border-radius: 0;
}

.kyc-details_right {
  margin: auto;
}

.kyc-details_right .epin-button-container {
  margin: 20px 0px 0px 0px;
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.kyc-details_right table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.kyc-details_right table td,
.kyc-details_right table th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 10px;
  width: 200px;
}

.kyc-details_right table tr:nth-child(even) {
  background-color: #dddddd;
}

.dialog_img {
  border-radius: 0;
}
