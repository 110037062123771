@import url('https://fonts.googleapis.com/css2?family=Aleo:wght@700&family=Charm:wght@400;700&family=Lato&family=Montserrat:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
}

/* 
// For Heading 
font-family: 'Aleo', serif;
font-weight: 700;

// For Sub Heading
font-family: 'Montserrat', sans-serif;
font-weight: 600;

// For Content
font-family: 'Lato', sans-serif;
font-weight: 400;

// For Special-text/Quotes 
font-family: 'Charm', cursive;
font-weight: 700;
font-weight: 400; -> Regular size */