.gcDetailsContainer .left-side {
  display: inline-block;
  width: 90%;
  margin: 0 auto;
}

.gcDetailsContainer .left-side form {
  margin: 20px;
}

.preview-img-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.preview_img_container {
  width: 100%;
  width: 49%;
}

.preview_img_container img {
  height: 100%;
  width: 100%;
}
.input-form_flex-container {
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
}

.input-form_flex-container > .input-form {
  width: 49%;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .rest .gcDetailsContainer {
    flex-direction: column-reverse;
  }

  .gcDetailsContainer .left-side {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .rest .category_giftCard_container {
    flex-direction: column;
  }
}
