a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  font-size: 17px;
}

.sidebar {
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0%;
  background-color: #ffffff;
  border-right: 1px rgb(209, 209, 209) solid;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar .top {
  text-align: center;
  margin: 25px 0px;
}

.sidebar .top img {
  /* height: 100px; */
  width: 100px;
  border-radius: 0;
}

.sidebar .center .profile-img-container {
  text-align: center;
  margin: 20px 0px;
}

.sidebar .center .profile-img-container img {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.sidebar .bottom ul {
  margin-top: 20px;
}

.sidebar .bottom ul li {
  padding: 10px;
  list-style: none;
  transition: all 0.2s ease;
  position: relative;
  margin: 10px 0px;
  cursor: pointer;
}

.sidebar .bottom ul .treeContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: none;
}

.sidebar .bottom ul .treeContainer > span {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  margin-left: 0.1em;
}

.sidebar .bottom ul li:after {
  content: "";
  position: absolute;
  background-color: #f35e63;
  height: 100%;
  width: 2px;
  top: 0;
  left: 0%;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.sidebar .bottom ul li:hover:after {
  content: "";
  width: 100%;
  top: 0;
  right: 0;
}

.sidebar .bottom ul li a {
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 14px;
}

.sidebar .bottom ul li a > .treeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.sidebar .bottom ul li a > .treeContainer > span {
  color: #000000;
  font-weight: 700;
}
.sidebar .bottom ul li:hover a {
  color: white;
}

.sidebar .bottom ul li svg {
  text-align: center;
  width: 50px;
  font-size: 20px;
}

/* dropdown */
.has-dropdown {
  position: relative;
}

.has-dropdown > .dropdown {
  position: absolute;
  width: 100%;
  top: -50px;
  right: -200px;
  background-color: #ffffff;
  border: 1px rgb(250, 250, 250) solid;
  display: none;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
}

.has-dropdown > ul a {
  color: #000000;
}

.dropdown li {
  position: relative;
  z-index: 1;
}

.dropdown li a {
  color: #000000;
  font-weight: 500;
}

.dropdown li::before {
  content: " ";
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 3px;
  background-color: #f35e63;
  transition: all 0.3s ease-in-out;
  z-index: -1;
}

.dropdown li:hover::before {
  width: 100%;
}

.has-dropdown:hover .dropdown {
  display: block;
}
