.cateDetailsContainer .left-side {
  display: inline-block;
  width: 65%;
  margin: 0;
}

.cateDetailsContainer .left-side form {
  margin: 20px;
}

.input-form_flex-container {
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
}

.input-form_flex-container > .input-form {
  width: 49%;
}

.cateDetailsContainer .right-side {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cateDetailsContainer .right-side .preview_img_container {
  width: 100%;
}

.cateDetailsContainer .right-side .preview_img_container img {
  height: 100%;
  width: 100%;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .rest .cateDetailsContainer {
    flex-direction: column-reverse;
  }

  .cateDetailsContainer .left-side {
    width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .rest .category_giftCard_container {
    flex-direction: column;
  }
}
