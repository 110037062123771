.form_container {
  width: 100%;
  background: #ffffff;
  position: relative;
}
.form_container label {
  font-size: 14px;
  color: rgb(36, 36, 36);
}

.form_container form {
  width: 50%;
  margin: auto;
}

.flex_input,
.flex_input_three {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.flex_input > div {
  width: 49%;
}

.flex_input_three > div {
  width: 32%;
}

.loading_state {
  height: 100%;
  width: 100%;
  background: #aca5a556;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading_state p {
  color: red;
  background: #fff;
  padding: 20px;
  margin-bottom: 30px;
}
