.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  /* background: #000000; */
  background: #ffffff;
  box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
  border: 1px #ffffff solid;
  position: relative;
  width: 23%;
}

.card::before {
  position: absolute;
  content: " ";
  background-color: #ff2a63;
  height: 100%;
  width: 5px;
}

.card:nth-child(2):before,
.card:nth-child(6):before {
  background-color: #7a99ff;
}

.card:nth-child(3):before,
.card:nth-child(7):before {
  background-color: #ca00fd;
}

.card:nth-child(4):before,
.card:nth-child(8):before {
  background-color: #87ceeb;
}

.card .icon-container {
  background-color: #ffa3bb;
  color: #850829;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card svg {
  margin: 5px;
  height: 30px;
  width: 30px;
}

.card:nth-child(2) .icon-container,
.card:nth-child(6) .icon-container {
  background-color: #a7bcff;
  color: #002394;
}

.card:nth-child(3) .icon-container,
.card:nth-child(7) .icon-container {
  background-color: #eda3ff;
  color: #56006b;
}

.card:nth-child(4) .icon-container,
.card:nth-child(8) .icon-container {
  background-color: #beedff;
  color: #005577;
}

.card .icon-container {
  border-radius: 5px;
  margin-left: 20px;
}

.card .content-container {
  margin: 20px 10px;
  width: 60%;
}

.card .content-container span {
  color: #ff2a63;
  font-size: 18px;
  font-weight: bold;
}
.card:nth-child(2) .content-container span,
.card:nth-child(6) .content-container span {
  color: #7a99ff;
}

.card:nth-child(3) .content-container span,
.card:nth-child(7) .content-container span {
  color: #ca00fd;
}

.card:nth-child(4) .content-container span,
.card:nth-child(8) .content-container span {
  color: #005577;
}

.card .content-container .card-head {
  margin: 10px 0px;
  /* font-size: 17px; */
  /* color: #ffffff; */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (max-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .card {
    width: 24%;
  }

  .card .content-container {
    margin: 15px 10px;
  }

  .card .content-container .card-head {
    margin: 5px 0px;
    font-size: 14px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 600px) {
  .card {
    width: 49%;
    margin: 10px 0px;
  }

  .card .content-container {
    margin: 10px 10px;
  }

  .card svg {
    margin: 5px;
    height: 28px;
    width: 28px;
  }

  .card .content-container span {
    font-size: 16px;
  }

  .card .content-container .card-head {
    font-size: 13px;
  }
}
