.chart {
    /* box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47); */
    padding: 10px;
    color: gray;
    margin: 10px 0px;
    background-color: #fff;
    /* background-color: #000000; */
}

.lineChart {
    /* box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47); */
    padding: 10px;
    color: gray;
}

.chart .title {
    margin-bottom: 20px;
}

.chart .chartGrid {
    stroke: rgb(44, 44, 44);
}

.chart .recharts-cartesian-grid line {
    stroke: rgb(44, 44, 44);
}